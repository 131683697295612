import Cookies from 'js-cookie'

/**
 * Cookie banner preferences
 */
export class CookiePreferences {
  name
  value
  handler

  /**
   * @param {string} name
   * @param {(preferences: typeof this.defaults) => void} [handler]
   */
  constructor(name, handler) {
    this.name = name
    this.value = this.get()

    // Handle preferences on load
    this.handler = handler
    this.handler(this.value)
  }

  /**
   * @returns {{ essential: boolean; usage: boolean | null; dismissed: boolean }}
   */
  get defaults() {
    return {
      essential: true,
      usage: null,
      dismissed: false,
    }
  }

  /**
   * Read preferences from cookie
   * @returns {typeof this.defaults}
   */
  get() {
    let value

    try {
      value = this.parse()
    } catch {
      value = this.defaults
    }

    return { ...this.defaults, ...value }
  }

  /**
   * Write preferences to cookie
   * @param {Partial<typeof this.defaults>} preferences
   */
  set(preferences) {
    this.value = { ...this.get(), ...preferences }

    // Save to cookie
    Cookies.set(this.name, `j:${JSON.stringify(this.value)}`, {
      expires: 365,
      sameSite: 'lax',
      secure: location.protocol === 'https:',
    })

    // Trigger update handler
    this.handler(this.value)
  }

  /**
   * Parse preferences from cookie
   * @returns {Partial<typeof this.defaults>}
   */
  parse() {
    let value

    // Untrusted cookie value
    const unparsed = Cookies.get(this.name)

    // Attempt to parse
    if (unparsed?.startsWith('j:')) {
      value = JSON.parse(unparsed.slice(2))
    }

    // Expect preferences object
    return typeof value === 'object' ? value : this.defaults
  }
}
