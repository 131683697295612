import { SkipLink } from 'govuk-frontend'

/**
 * Initialise skip links
 * @param {NodeListOf<Element>} $skipLinks
 */
export function init($skipLinks) {
  $skipLinks.forEach((/** @type {Element} */ $skipLink) => {
    if (!($skipLink instanceof HTMLAnchorElement)) {
      return
    }

    const skipLink = new SkipLink($skipLink)

    // Already in hash fragment? Focus and announce
    if ($skipLink.hash === '#search-results' && location.hash === `#${skipLink.getFragmentFromUrl()}`) {
      skipLink.focusLinkedElement()
    }
  })
}
